import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Modal } from '../modal/Modal';
import { SubscribeCard } from '../subscribe-card/SubscribeCard';

import cn from './Header.module.scss';

export const Header: React.FC = () => {
  const [is_open, setIsOpen] = useState(false);
  const [top, setTop] = useState(0);
  const lastScrollTop = useRef(0);

  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      const headerHeight = document.getElementById('header')?.offsetHeight || 0;

      if (currentScroll <= 0) {
        setTop(0);
      } else if (currentScroll > lastScrollTop.current) {
        setTop((prev) => Math.min(prev + (currentScroll - lastScrollTop.current), headerHeight));
      } else {
        setTop((prev) => Math.max(prev - (lastScrollTop.current - currentScroll), 0));
      }

      lastScrollTop.current = currentScroll;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onClickPositivLogo = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div id="header" className={cn.header} style={{ top: `-${top}px` }}>
      <img className={cn.logo} src="/cognitiv-positiv-logo.png" alt="cognitiv positiv logo" onClick={onClickPositivLogo} />
      <div className={cn.container}>
        <p className={cn.date}>{formattedDate}</p>
        <button className={cn.button} onClick={() => setIsOpen(true)}>
          SUBSCRIBE
        </button>
      </div>
      <Modal open={is_open} onClose={() => setIsOpen(false)}>
        <SubscribeCard />
      </Modal>
    </div>
  );
};
