import { faEquals, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { SENTIMENT } from '../PositivPulse';

export const normalizePulseData = (res: any) => {
  const [today, yesterday] = res
    .filter((item: any) => item.Day !== 'today' && item.Day !== 'yesterday')
    .sort((a: any, b: any) => {
      const dateA = new Date(a.Day);
      const dateB = new Date(b.Day);
      return dateB.getTime() - dateA.getTime();
    });

  const today_positive = Math.round((today.Positive / today.Total) * 1000) / 10;
  const today_negative = Math.round((today.Negative / today.Total) * 1000) / 10;
  const today_neutral = Math.round((today.Neutral / today.Total) * 1000) / 10;
  const yesterday_positive = Math.round((yesterday.Positive / yesterday.Total) * 1000) / 10;
  const yesterday_negative = Math.round((yesterday.Negative / yesterday.Total) * 1000) / 10;
  const today_ratio = Math.round((today_positive / (today_positive + today_negative)) * 10000) / 100;
  const yesterday_ratio = Math.round((yesterday_positive / (yesterday_positive + yesterday_negative)) * 10000) / 100;
  const delta = Math.round((today_ratio / yesterday_ratio - 1) * 1000) / 10;
  const delta_abs = Math.abs(delta);
  const delta_is_positive = Math.sign(delta) === 1;

  return {
    positive: today_positive,
    negative: today_negative,
    neutral: today_neutral,
    delta: delta_abs,
    delta_is_positive,
  };
};

export const normalizeGraphData = (res: any, sentiment: SENTIMENT) => {
  const sorted_data = res
    .filter((item: any) => item.Day !== 'today' && item.Day !== 'yesterday')
    .sort((a: any, b: any) => {
      const dateA = new Date(a.Day);
      const dateB = new Date(b.Day);
      return dateA.getTime() - dateB.getTime();
    });

  const title = sentiment === SENTIMENT.POSITIVE ? 'Positive' : sentiment === SENTIMENT.NEGATIVE ? 'Negative' : 'Neutral';
  const icon = sentiment === SENTIMENT.POSITIVE ? faPlus : sentiment === SENTIMENT.NEGATIVE ? faMinus : faEquals;
  const labels = sorted_data.map((item: any) => item.Day);
  const data = sorted_data.map(
    (item: any) =>
      Math.round(
        ((sentiment === SENTIMENT.POSITIVE ? item.Positive : sentiment === SENTIMENT.NEGATIVE ? item.Negative : item.Neutral) / item.Total) * 1000,
      ) / 10,
  );

  return { title, icon, labels, data };
};
