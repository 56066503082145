import React from 'react';
import { Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';

import { GraphData } from '../PositivPulse';

import cn from './SentimentGraph.module.scss';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const SentimentGraph = ({ title, icon, labels, data }: GraphData) => {
  const graph_data = {
    labels,
    datasets: [
      {
        data,
        fill: true,
        backgroundColor: 'rgba(255, 226, 31, 0.2)',
        borderColor: 'rgba(255, 226, 31, 1)',
        tension: 0.2,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className={cn.container}>
      <div className={cn.title}>
        <div className={cn.icon}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <h2>{title} Sentiment Over Time</h2>
      </div>
      <div className={cn.graph}>
        <Line data={graph_data} options={options} />
      </div>
    </div>
  );
};
